<template>
  <div
    class="shadow-sm border border-gray-200 rounded-lg"
    :class="props.clickable ? 'group cursor-pointer' : ''"
  >
    <div class="flex items-center w-full p-3">
      <div
        v-if="props.expandable"
        class="sm:w-8 flex-shrink-0"
      >
        <Button
          content-icon="chevron-right"
          size="small"
          class="-ml-1 [&_svg]:transform [&_svg]:transition-transform"
          :class="{
            '[&_svg]:rotate-90': isExpanded,
          }"
          @click="() => isExpanded = !isExpanded"
        />
      </div>
      <Avatar
        size="md"
        type="square"
        :icon="props.plan.type === 'subscription' ? 'infinity' : 'package'"
        class="border-none"
        :class="props.showStatus && plan.status !== 'active' ? '' : 'bg-primary-100 [&_svg]:text-primary-500'"
      />
      <div class="flex-1 flex items-center">
        <div class="mx-3 md:w-40 flex-1">
          <div class="text-xs text-gray-600">
            {{ props.plan.type === 'singleUse' ? useT('abc1099') : useT('abc1100') }}
          </div>
          <div class="text-sm font-medium text-gray-900">
            {{ _capitalize(props.plan.config.tier) }}
          </div>
        </div>
        <div
          v-if="props.showStatus"
          class="sm:w-32"
        >
          <Badge
            :text="useT(plan.status)"
            :type="statusTypeMap[plan.status]"
          />
        </div>
      </div>
      <div class="flex items-center">
        <div
          v-if="maxCapacity !== undefined"
          class="flex items-center flex-col sm:flex-row"
        >
          <NumberInput
            v-model="maxCapacity"
            :min="props.plan.config.maxCapacity || 100"
            :max="10000"
            :step="100"
            class="min-w-[7rem]"
          />
          <div class="ml-2 text-sm text-gray-600">
            {{ useT('abc889', { value: '' }) }}
          </div>
        </div>
        <div
          v-else
          class="text-gray-500 text-sm text-right px-1 sm:px-3 sm:w-32"
        >
          {{ useT('abc889', { value: props.plan.config.maxCapacity }) }}
        </div>
        <Button
          v-if="props.upgradeable"
          type="regular-outline"
          size="small"
          class="ml-3"
          :disabled-tooltip="useT('abc1134')"
          :disabled="useAuthStore().user?.role !== 'admin'"
          @click="usePlansStore().openUpgrade(props.plan)"
        >
          {{ useT('abc1108') }}
        </Button>
        <Button
          v-if="props.clickable"
          size="small"
          content-icon="chevron-right"
          class="group-hover:translate-x-0.5"
        />
      </div>
    </div>
    <el-collapse-transition>
      <div
        v-show="isExpanded"
        v-loading="isEventsLoading"
      >
        <div
          v-if="isEventsLoading"
          class="h-20 border-t border-gray-200"
        />

        <div v-else-if="events?.length">
          <el-table
            :data="events"
            :show-header="false"
            :default-sort="{ prop: 'general.title', order: 'ascending' }"
          >
            <el-table-column
              prop="general.title"
              class-name="px-0"
            >
              <template #default="scope">
                <nuxt-link
                  :to="`/events/${scope.row?._id}/guests`"
                  class="ml-11 hover:underline hover:text-gray-900 block"
                >
                  {{ scope.row.general?.title }}
                </nuxt-link>
              </template>
            </el-table-column>

            <el-table-column
              prop="general.startDate"
            >
              <template #default="scope">
                {{ getFormattedDate(scope.row.general?.startDate, {
                  lang: useAuthStore().user?.locale,
                  langVersion: 'browser',
                  format: 'll',
                  timezone: scope.row?.general?.timezone,
                }) }}
              </template>
            </el-table-column>
          </el-table>

          <div
            v-if="events.length"
            class="el-table__counter pl-11"
          >
            {{ useT('abc18', { n: events.length }) }}
          </div>
        </div>

        <div
          v-else
          class="border-t border-gray-200"
        >
          <EmptyState
            :title="useT('abc1124')"
            :description="useT('abc1125')"
          />
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup>
const props = defineProps({
  maxCapacity: {
    type: Number,
    default: undefined,
  },
  plan: {
    type: Object,
    required: true,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  expandable: {
    type: Boolean,
    default: false,
  },
  upgradeable: {
    type: Boolean,
    default: false,
  },
  showStatus: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:maxCapacity']);

// Refs
const maxCapacity = useVModel(props, 'maxCapacity', emit);
const statusTypeMap = {
  active: 'success',
  expired: 'warning',
  used: 'neutral',
  deactivated: 'critical',
};
const events = ref([]);
const isEventsLoading = ref(false);
const isExpanded = ref(false);

// Methods
const getEvents = async() => {
  isEventsLoading.value = true;

  const response = await useEventsStore().getEvents({
    plan: props.plan._id,
  });

  if (response) {
    events.value = response.results;
  }

  isEventsLoading.value = false;
};

// Watchers
watch(isExpanded, (value) => {
  if (value) {
    getEvents();
  }
});
</script>
